import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import { asyncResend2FA, asyncVerify2FA } from '../../../services'
import { preventMouseEvent } from '../../../utils'
import Back from '../../../styles/images/back-green-icon.svg'
import { processLoading } from '../../../initialization'
import { DEVICE_ID } from '../../../constants'
import { InputBase } from '../../../components'

export const ConfirmOTPPage = () => {
  const [state, setState] = useState<{
    email: string
    emailError?: string
    emailSuccess?: boolean
  }>({
    email: '',
    emailSuccess: false
  })
  const navigate = useNavigate()
  const [resendTimer, setResendTimer] = useState<number>(60)
  const [canResend, setCanResend] = useState<boolean>(false)
  const [code, setCode] = useState<string[]>(Array(4).fill(''))
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])
  // const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          setCanResend(true)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const onSubmit = async () => {
    try {
      const deviceId = localStorage.getItem(DEVICE_ID) ?? ''
      if (!deviceId) {
        const errorMessage = 'Your login was not successful, please try again.'
        setState({ ...state, emailError: errorMessage })
        toast.error(`${errorMessage}`)
      }

      const codeString = code.join('')
      const data = {
        token: codeString,
        ...(deviceId && { deviceId })
      }
      const response = await processLoading(asyncVerify2FA(data))
      const { token } = response
      if (token) {
        window.location.href = '/'
      } else {
        const errorMessage = 'Your login was not successful, please try again.'
        setState({ ...state, emailError: errorMessage })
        toast.error(`${errorMessage}`)
      }
    } catch (error: any) {
      let errorMessage = error.response?.data?.error?.message || error.message
      setState({ ...state, emailError: errorMessage })
      toast.error(`${errorMessage}`)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  const handleResendCode = async () => {
    try {
      // Logic to resend the code
      const deviceId = localStorage.getItem(DEVICE_ID) ?? ''
      const data = {
        ...(deviceId && { deviceId })
      }
      setCanResend(false)
      await processLoading(asyncResend2FA(data))
      setResendTimer(60)
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setCanResend(true)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    } catch (error: any) {
      let errorMessage = error.response?.data?.error?.message || error.message
      setState({ ...state, emailError: errorMessage })
      toast.error(`${errorMessage}`)
    }
  }

  const handleInputChange = (index: number, value: string) => {
    if (/^[0-9]$/.test(value) || value === '') {
      const newCode = [...code]
      newCode[index] = value
      setCode(newCode)

      // Move focus to the next input if the value is not empty and it's not the last input
      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  return (
    <div className='confirm-otp-page'>
      <div className='confirm-otp-page-header'>
        <p className='title'>
          <img src={Back} alt='logo' className='back-icon' onClick={onBack} />
          We have sent a code for verification
        </p>
        <span className='description'>Please enter the 4-digit code that has been sent to your email.</span>
        <span className='note'>Your code will expire in 10 minutes.</span>
      </div>
      <form className='confirm-otp-form' onSubmit={onSubmit}>
        <div className='code-input-container'>
          {code.map((digit, index) => (
            <InputBase
              key={index}
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              className='code-input'
              ref={(el: HTMLInputElement | null) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        <div className='resend-link'>
          <Link
            onClick={canResend ? handleResendCode : undefined}
            style={{ pointerEvents: canResend ? 'auto' : 'none', color: canResend ? 'blue' : 'gray' }}
            to={''}
          >
            Resend Code {resendTimer > 0 && `(${resendTimer}s)`}
            {/* Resend Code ({resendTimer}s) */}
          </Link>
        </div>
        <Button type='primary' onClick={preventMouseEvent(onSubmit)}>
          Next
        </Button>
      </form>
    </div>
  )
}

export default ConfirmOTPPage
