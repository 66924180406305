import { SWITCH_FUNCTION } from '.'
import { COUNTRY_CODE, ResidentialType } from '../definition'

export const CONFIG_RESIDENTIAL_TYPE = {
  [ResidentialType.HDB]: {
    show: true,
    label: ResidentialType.HDB
  },
  [ResidentialType.Condo]: {
    show: true,
    label: ResidentialType.Condo
  },
  [ResidentialType.Landed]: {
    show: true,
    label: ResidentialType.Landed
  },
  [ResidentialType.Commercial]: {
    show: true,
    label: ResidentialType.Commercial
  },
  [ResidentialType.Industrial]: {
    show: true,
    label: ResidentialType.Industrial
  }
}

export const CONFIG_COUNTRY_CODE = {
  [COUNTRY_CODE._60]: {
    show: true,
    label: COUNTRY_CODE._60
  },
  [COUNTRY_CODE._62]: {
    show: true,
    label: COUNTRY_CODE._62
  },
  [COUNTRY_CODE._65]: {
    show: true,
    label: COUNTRY_CODE._65
  },
  [COUNTRY_CODE._84]: {
    show: true,
    label: COUNTRY_CODE._84
  },
  [COUNTRY_CODE._86]: {
    show: true,
    label: COUNTRY_CODE._86
  }
}

export const SERVICE_MANDATORY = {
  name: true,
  price: true,
  description: true,
  status: true,
  firstBanner: true,
  secondBanner: true,
  mainCategory: true,
  subCategory: true,
  childCategory: true
}

export const EMPLOYEE_MANDATORY = {
  name: true,
  role: true,
  contact: true,
  email: SWITCH_FUNCTION.tech_cus_required_only_contact ? false : true,
  password: true
}

export const CUSTOMER_MANDATORY = {
  name: true,
  prefferPaymentTerm: true,
  contact: true,
  email: SWITCH_FUNCTION.tech_cus_required_only_contact ? false : true,
  password: true,
  customerType: true
}

export const ADDRESS_MANDATORY = {
  name: true,
  line1: true,
  line2: false,
  postalCode: true,
  country: false,
  city: false,
  residentialType: true
}

export const EMPLOYEE_LABEL = 'employee'

export const CUSTOMER_SUB_CONTACTS = true

export const SETTING_STRIPE = false
